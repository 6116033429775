// Lib
import React, { useEffect } from 'react'
import { useFormikContext, withFormik, Form } from 'formik'
import moment from 'moment'
import { useSearchParams } from 'react-router-dom'

// Include in project
import styles from './index.module.scss'
import Layout from 'layouts'
import { useTranslation } from 'react-i18next'
import { StepProgressBarWithFormik } from 'component'
import {
  CampaignRestrictionsSpecialModeEdit,
  CampaignRestrictionsSpecialModeView,
  DocumentCampaign,
  FooterButtonCreateCampaign,
  SetupNewCampaignModeEdit,
  SetupNewCampaignModeView,
  TargetOfCondition,
} from 'container'
import { useCampaignSpecial } from 'hooks'
import { ECampaignStatus } from 'utils/generated'
import { tranformationDataIntoClient, ETranformationDataIntoClient } from 'utils/tranformData'
import yupSpecialCampaign from 'validations/yupSpecialCampaign.validate'

const CreateSpecialCampaign: React.FC = () => {
  const { t } = useTranslation()
  const { values, setFieldValue } = useFormikContext<any>()
  const { getCampaign, createSpecialCampaign, updateSpecialCampaign, requestApproveCampaign } = useCampaignSpecial()
  const [searchParams] = useSearchParams()
  const campaignID = searchParams.get('campaignID')

  useEffect(() => {
    if (!values.campaign.campaignID && !searchParams.get('campaignID')) return

    getCampaign({
      variables: {
        campaignId: values.campaign.campaignID || searchParams.get('campaignID'),
        type: 'SPECIAL',
      },
    }).then((res) => {
      const detailCampaign = res.data.campaignDetail
      if (detailCampaign.res_code === '99') return

      const dataTranform = tranformationDataIntoClient({
        values: detailCampaign,
        type: ETranformationDataIntoClient.DetailSpecialCampaign,
      })
      setFieldValue('campaign', dataTranform)

      if (
        detailCampaign.campaignStatus === ECampaignStatus.Draft ||
        detailCampaign.campaignStatus === ECampaignStatus.Rejected
      ) {
        setFieldValue('activeStep', 1)
      } else {
        setFieldValue('activeStep', 2)
      }
    })
  }, [searchParams.get('campaignID'), values.campaign.campaignID])

  // Set Values targetProduct from targetProductDummy
  useEffect(() => {
    if (values.activeStep !== 0) return
    if (!values.campaign.campaignType) return

    const filterDupValue = values.campaign.targetProduct.filter((ele: any) => {
      const findSameValue = values.campaign.targetProductDummy.find((eleDummy: any) => eleDummy.value === ele.value)

      return Boolean(findSameValue)
    })

    const filterSubtact = values.campaign.targetProductDummy.filter((ele: any) => {
      const findSameValue = filterDupValue.find((eleDubValue: any) => eleDubValue.value === ele.value)
      if (findSameValue) return false
      else return true
    })

    const productListAddPoint = filterSubtact.map((data: any) => {
      if (
        values.campaign.campaignType === 'SPECIAL_PlusBonusPoint' ||
        values.campaign.campaignType === 'SPECIAL_AchievedTargeting'
      ) {
        return { ...data, levelList: [{ point: 0 }] }
      } else if (
        values.campaign.campaignType === 'SPECIAL_MonthlyAccTargeting' ||
        values.campaign.campaignType === 'SPECIAL_MonthlyEachTargeting'
        // && values.campaign.timeDuration.length === 2
      ) {
        // SPECIAL_MONTHLY_EACH_TARGETING And SPECIAL_MONTHLY_ACC_TARGETING
        const monthDifference = moment(values.campaign.timeDuration[1])
          .endOf('month')
          .diff(moment(values.campaign.timeDuration[0]).startOf('month'), 'months')

        const pointOfMonth: any = []
        for (let index = 0; index < monthDifference + 1; index++) {
          pointOfMonth.push({ point: 0 })
        }
        return { ...data, levelList: pointOfMonth }
      } else {
        // SPECIAL_StepLevelTargeting
        return { ...data, levelList: [{ point: 0 }, { point: 0 }, { point: 0 }] }
      }
    })

    setFieldValue('campaign.targetProduct', [...filterDupValue, ...productListAddPoint])
  }, [
    values.campaign.targetProductDummy,
    values.campaign.campaignType,
    values.campaign.targetType,
    values.campaign.timeDuration,
  ])

  // Set Values targetTypeValue from targetTypeValueDummy
  useEffect(() => {
    if (values.activeStep !== 0) return
    if (!values.campaign.campaignType) return
    if (values.campaign.targetProductDummy.length === 0) return

    if (values.campaign.campaignType === 'SPECIAL_PlusBonusPoint') return // SPECIAL_PlusBonusPoint need targetTypeValue to Empty Array

    const productListAddPoint = values.campaign.targetTypeValueDummy.map((data: any) => {
      if (values.campaign.campaignType === 'SPECIAL_StepLevelTargeting') {
        const productList = values.campaign.targetProductDummy.map((product: any) => ({
          productID: product.value,
          productName: product.label,
          targetList: [{ amount: 0 }, { amount: 0 }, { amount: 0 }],
        }))
        return { ...data, productList: productList }
      } else if (values.campaign.campaignType === 'SPECIAL_AchievedTargeting') {
        const productList = values.campaign.targetProductDummy.map((product: any) => ({
          productID: product.value,
          productName: product.label,
          targetList: [{ amount: 0 }],
        }))
        return { ...data, productList: productList }
      } else {
        // SPECIAL_MONTHLY_EACH_TARGETING And SPECIAL_MONTHLY_ACC_TARGETING
        const monthDifference = moment(values.campaign.timeDuration[1])
          .endOf('month')
          .diff(moment(values.campaign.timeDuration[0]).startOf('month'), 'months')

        const amountOfMonth: any = []
        for (let index = 0; index < monthDifference + 1; index++) {
          amountOfMonth.push({ amount: 0 })
        }
        const productList = values.campaign.targetProductDummy.map((product: any) => ({
          productID: product.value,
          productName: product.label,
          targetList: amountOfMonth,
        }))
        return { ...data, productList: productList }
      }
    })

    setFieldValue('campaign.targetTypeValue', productListAddPoint)
  }, [
    values.campaign.targetProductDummy,
    values.campaign.targetTypeValueDummy,
    values.campaign.campaignType,
    values.campaign.timeDuration,
  ])

  // START Clear Value
  useEffect(() => {
    if (values.activeStep !== 0) return

    // Clear Data if campaign.campaignType
    setFieldValue('campaign.shopType', '')
    setFieldValue('campaign.targetType', '')
    setFieldValue('campaign.targetTypeValue', [])
    setFieldValue('campaign.targetTypeValueDummy', [])
    setFieldValue('campaign.tagRetainer', [])
    setFieldValue('campaign.timeDuration', [])
    setFieldValue('campaign.isSubRTLCal', false)
  }, [values.campaign.campaignType])
  useEffect(() => {
    if (values.activeStep !== 0) return

    // Clear Data if campaign.campaignType
    setFieldValue('campaign.shopType', '')
    setFieldValue('campaign.targetTypeValue', [])
    setFieldValue('campaign.targetTypeValueDummy', [])
    setFieldValue('campaign.tagRetainer', [])
    setFieldValue('campaign.isSubRTLCal', false)
  }, [values.campaign.targetType])
  useEffect(() => {
    if (values.activeStep !== 0) return

    if (values.campaign.shopType === 'ALL') {
      setFieldValue('campaign.isSubRTLCal', true)
    } else {
      // Clear Data if campaign.campaignType
      setFieldValue('campaign.isSubRTLCal', false)
    }
  }, [values.campaign.shopType])
  // END Clear Value

  return (
    <Layout open={true} section={t('createSpecialCampaign.section')}>
      <Form className={styles.container}>
        <StepProgressBarWithFormik />
        {values.activeStep === 0 ? (
          <div className={styles.wrapperCard}>
            <SetupNewCampaignModeEdit type="SPECIAL" />
            <CampaignRestrictionsSpecialModeEdit />
            <TargetOfCondition />
            <DocumentCampaign isDelayTime={campaignID !== null} />
          </div>
        ) : values.activeStep === 1 || values.activeStep === 2 ? (
          <div className={styles.wrapperCard}>
            <SetupNewCampaignModeView />
            <CampaignRestrictionsSpecialModeView />
            <TargetOfCondition />
            <DocumentCampaign isDelayTime={campaignID !== null} />
          </div>
        ) : (
          ''
        )}
        <FooterButtonCreateCampaign
          type="SPECIAL"
          createSpecialCampaign={createSpecialCampaign}
          updateSpecialCampaign={updateSpecialCampaign}
          requestApproveCampaign={requestApproveCampaign}
        />
      </Form>
    </Layout>
  )
}

const EnhancedCreateSpecialCampaign = withFormik({
  mapPropsToValues: () => ({
    activeStep: 0,
    campaign: {
      // Value in container SetupNewCampaignModeEdit
      campaignName: '',
      campaignDescription: '',
      campaignType: '',
      campaignGoal: '',

      // Value in container CampaignRestrictionsSpecialModeEdit
      shopType: '',
      targetType: '',
      targetTypeValue: [],
      targetTypeValueDummy: [], // Hack AutoComplete because filterSelectedOptions Not Work if Option and value not match
      tagRetainer: [],
      targetProduct: [],
      targetProductDummy: [], // Hack AutoComplete because filterSelectedOptions Not Work if Option and value not match
      timeDuration: [],
      isSubRTLCal: false,
      imageList: [], // Keep Image Url

      remark: '', // Reason Why leader is reject campaign in container SetupNewCampaignModeView

      campaignID: '',
      campaignStatus: 'DRAFT',
    },
  }),
  validateOnMount: true,
  validationSchema: yupSpecialCampaign,
  handleSubmit: () => {
    console.log('Skip Handle Sumit of formik')
  },
})(CreateSpecialCampaign)
export default EnhancedCreateSpecialCampaign
