// Lib
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import axios from 'axios'
import { useFormikContext } from 'formik'

//Images
import cloudSVG from 'images/UploadFile/cloud.svg'
import fileImgSVG from 'images/UploadFile/file.svg'
import deleteFileSVG from 'images/UploadFile/delete.svg'
import lockPNG from 'images/commons/lock.png'

// Include in project
import styles from './index.module.scss'
import { Button } from 'component'
import { ECampaignStatus } from 'utils/generated'

type Props = {
  isDelayTime?: boolean
}

const UploadFile: React.FC<Props> = ({ isDelayTime = false }): JSX.Element => {
  const { t } = useTranslation()
  const { values, setFieldValue } = useFormikContext<any>()
  const [timeLeft, setTimeLeft] = useState<number>(10)

  useEffect(() => {
    if (isDelayTime) {
      if (timeLeft === 0) {
        setTimeLeft(0)
      }

      if (!timeLeft) return

      const intervalId = setInterval(() => {
        setTimeLeft(timeLeft - 1)
      }, 1000)

      return () => clearInterval(intervalId)
    }
  }, [timeLeft, isDelayTime])

  const onDeleteFile = (index: number) => {
    const cloneImageList: string[] = [...values.campaign.imageList]

    setFieldValue('campaign.imageList', [...cloneImageList.slice(0, index), ...cloneImageList.slice(index + 1)])
  }

  const upload = (file: any) => {
    if (!file) return

    const form = new FormData()
    form.append('file', file)

    axios({
      method: 'post',
      url: `${process.env['REACT_APP_END_POINT']}/${process.env['REACT_APP_END_POINT_PATH']}${process.env['REACT_APP_END_POINT_STAGE']}-httpUploadFile`,
      data: form,
      headers: {
        'Content-Type': `multipart/form-data;`,
      },
    })
      .then((res) => {
        const cloneImageList: string[] = [...values.campaign.imageList]
        setFieldValue('campaign.imageList', [...cloneImageList, res.data.imagePath[0]])
      })
      .catch((err) => {
        console.log(err)
      })
  }

  const ButtonInput: React.FC = () => {
    return (
      <div className={styles.groupInput}>
        <Button typeButton={'button'} type={'outline'} disabled={false} name={t<string>('common.uploadFile')} />
        <input
          type="file"
          accept="image/jpeg,image/png,application/pdf"
          onChange={(e: any) => upload(e.target.files[0])}
        />
      </div>
    )
  }

  function convertUrlToFileName(url: string) {
    const splitUrl = url.split('/')
    const fileName = decodeURIComponent(splitUrl[splitUrl.length - 1].split('?')[0])
    const fileType = fileName.split('.')[fileName.split('.').length - 1]
    return { fileName, fileType }
  }

  return (
    <div className={styles.container}>
      {values.campaign.imageList.length > 0 ? (
        <div className={styles.wrapperShowFile}>
          <div className={styles.groupFile}>
            {values.campaign.imageList.map((file: string, index: number) => (
              <div className={styles.groupCardContainer} key={index}>
                <div
                  className={styles.groupCardFile}
                  onClick={() => window.open(file)}
                  aria-checked={timeLeft == 0 && isDelayTime}
                >
                  <div className={styles.groupDelay} aria-checked={isDelayTime}>
                    <h6 className={styles.textRed}>Time left : {timeLeft}</h6>
                    <img
                      src={lockPNG}
                      alt="lock-icon"
                      width={30}
                      height={30}
                      className={styles.iconLock}
                      aria-checked={timeLeft == 0 && isDelayTime}
                    />
                  </div>

                  <div className={styles.grolockupImg}>
                    <img src={fileImgSVG} alt="fileImgPNG" />
                    <p className={styles.fileType}>{convertUrlToFileName(file).fileType}</p>
                  </div>
                  <pre className={styles.textFile}>{convertUrlToFileName(file).fileName}</pre>
                </div>
                {values.campaign.campaignStatus === ECampaignStatus.Draft && values.activeStep === 0 && (
                  <img
                    className={styles.groupCardBtnDelete}
                    src={deleteFileSVG}
                    alt="deleteFileSVG"
                    onClick={() => onDeleteFile(index)}
                  />
                )}
              </div>
            ))}
          </div>
          {values.campaign.campaignStatus === ECampaignStatus.Draft && values.activeStep === 0 && (
            <div className={styles.groupIcon}>
              <img src={cloudSVG} alt="cloudSVG" />
              <ButtonInput />
            </div>
          )}
        </div>
      ) : (
        <div className={styles.wrapperUpload}>
          {values.campaign.campaignStatus === ECampaignStatus.Draft && values.activeStep === 0 && (
            <>
              <img src={cloudSVG} alt="cloudSVG" />
              <div className={styles.groupText}>
                <q>{t<string>('uploadFile.title')}</q>
                <pre>{t<string>('uploadFile.subTitle')}</pre>
              </div>
              <ButtonInput />
            </>
          )}
        </div>
      )}
    </div>
  )
}

export default UploadFile
