// Lib
import React, { useEffect } from 'react'
import { useFormikContext, withFormik, Form } from 'formik'
import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'

// Include in project
import styles from './index.module.scss'
import Layout from 'layouts'
import { StepProgressBarWithFormik } from 'component'
import {
  CampaignRestrictionsModeEdit,
  CampaignRestrictionsModeView,
  DocumentCampaign,
  FooterButtonCreateCampaign,
  SetupNewCampaignModeEdit,
  SetupNewCampaignModeView,
  TargetOfCondition,
} from 'container'
import { tranformationDataIntoClient, ETranformationDataIntoClient } from 'utils/tranformData'
import { useCampaignNormal } from 'hooks'
import { ECampaignStatus, ECampaignType } from 'utils/generated'
import yupNormalCampaign from 'validations/yupNormalCampaign.validate'
import { IOption } from 'utils/interface'

const CreateNormalCampaign: React.FC = () => {
  const { t } = useTranslation()
  const { values, setFieldValue } = useFormikContext<any>()
  const { getCampaign, createNormalCampaign, updateNormalCampaign, requestApproveCampaign } = useCampaignNormal()
  const [searchParams] = useSearchParams()
  const campaignID = searchParams.get('campaignID')

  useEffect(() => {
    if (!values.campaign.campaignID && !searchParams.get('campaignID')) return

    getCampaign({
      variables: {
        campaignId: values.campaign.campaignID || searchParams.get('campaignID'),
        type: ECampaignType.Normal,
      },
    }).then((res) => {
      const detailCampaign = res.data.campaignDetail
      if (detailCampaign.res_code === '99') return

      const dataTranform = tranformationDataIntoClient({
        values: detailCampaign,
        type: ETranformationDataIntoClient.DetailNormalCampaign,
      })
      setFieldValue('campaign', dataTranform)

      if (
        detailCampaign.campaignStatus === ECampaignStatus.Draft ||
        detailCampaign.campaignStatus === ECampaignStatus.Rejected
      ) {
        setFieldValue('activeStep', 1)
      } else {
        setFieldValue('activeStep', 2)
      }
    })
  }, [searchParams.get('campaignID'), values.campaign.campaignID])

  // Use For add levelList into productList
  useEffect(() => {
    if (values.activeStep !== 0) return
    if (!values.campaign.productListDummy.length) return

    const filterDupValue = values.campaign.productList.filter((ele: any) => {
      const findSameValue = values.campaign.productListDummy.find((eleDummy: any) => eleDummy.value === ele.value)

      return Boolean(findSameValue)
    })

    const filterSubtact = values.campaign.productListDummy.filter((ele: any) => {
      const findSameValue = filterDupValue.find((eleDubValue: any) => eleDubValue.value === ele.value)
      if (findSameValue) return false
      else return true
    })

    const productListAddPoint = filterSubtact.map((data: IOption) => {
      return { ...data, levelList: [{ point: 0 }] }
    })

    setFieldValue('campaign.productList', [...filterDupValue, ...productListAddPoint])
  }, [values.campaign.productListDummy.length])

  return (
    <Layout open={true} section={t('createNormalCampaign.section')}>
      <Form className={styles.container}>
        <StepProgressBarWithFormik />
        {values.activeStep === 0 ? (
          <div className={styles.wrapperCard}>
            <SetupNewCampaignModeEdit type="NORMAL" />
            <CampaignRestrictionsModeEdit />
            <TargetOfCondition />
            <DocumentCampaign isDelayTime={campaignID !== null} />
          </div>
        ) : values.activeStep === 1 || values.activeStep === 2 ? (
          <div className={styles.wrapperCard}>
            <SetupNewCampaignModeView />
            <CampaignRestrictionsModeView />
            <TargetOfCondition />
            <DocumentCampaign isDelayTime={campaignID !== null} />
          </div>
        ) : (
          ''
        )}
        <FooterButtonCreateCampaign
          type="NORMAL"
          createNormalCampaign={createNormalCampaign}
          updateNormalCampaign={updateNormalCampaign}
          requestApproveCampaign={requestApproveCampaign}
        />
      </Form>
    </Layout>
  )
}

const EnhancedCreateNormalCampaign = withFormik({
  mapPropsToValues: () => ({
    activeStep: 0,
    campaign: {
      campaignID: '',
      campaignName: '',
      campaignDescription: '',
      campaignStatus: 'DRAFT',
      campaignGoal: '',
      campaignType: 'NORMAL',
      productList: [],
      productListDummy: [], // Hack AutoComplete because filterSelectedOptions Not Work if Option and value not match
      timeDuration: [],
      imageList: [], // Keep Image Url

      remark: '', // Reason Why leader is reject campaign in container SetupNewCampaignModeView
    },
  }),
  validateOnMount: true,
  validationSchema: yupNormalCampaign,
  handleSubmit: () => {
    console.log('Skip Handle Sumit of formik')
  },
})(CreateNormalCampaign)
export default EnhancedCreateNormalCampaign
