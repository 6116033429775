import React from 'react'
import { Navigate, Outlet } from 'react-router-dom'
import { useIntitialMasterData, useIntitialDecode } from 'hooks'
import { IDecodeToken } from 'states/slice/decodeToken'
import jwt_decode from 'jwt-decode'

const ProtectedRoute: React.FC = () => {
  const accessToken = localStorage.getItem('accessToken')
  const refreshToken = localStorage.getItem('refreshToken')

  if (!accessToken && !refreshToken) {
    return <Navigate to="/login" replace />
  }

  const decode: IDecodeToken = jwt_decode(accessToken as string)
  if (!decode.permission) {
    localStorage.clear()
    return <Navigate to="/login" replace />
  }

  useIntitialDecode()
  useIntitialMasterData()

  return <Outlet />
}

export default ProtectedRoute
