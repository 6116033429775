// Lib
import React from 'react'
import { Navigate, Outlet } from 'react-router-dom'

// Include in project
import { EPermissionToken, decodeToken } from 'utils/other'

const ProtectedRoutePermission: React.FC<{ permission: EPermissionToken[] }> = ({ permission }) => {
  const { permissionList } = decodeToken()

  function findCommonElements(arr1: string[], arr2: string[]) {
    return arr1.some((item) => arr2.includes(item))
  }

  if (!findCommonElements(permissionList, permission)) {
    // Can not open this page
    // Force Redirect to main page
    return <Navigate to="/" replace />
  }

  return <Outlet />
}

export default ProtectedRoutePermission
