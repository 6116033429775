// Lib
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import jwt_decode from 'jwt-decode'

// Include in project
import { setDecodeToken, IDecodeToken } from 'states/slice/decodeToken'
import type { RootState } from 'states/store'

const useIntitialDecode = () => {
  const dispatch = useDispatch()
  const getPermissionReducer = useSelector((state: RootState) => state.permission)

  const accessToken = localStorage.getItem('accessToken')
  useEffect(() => {
    if (!accessToken) return
    const decode: IDecodeToken = jwt_decode(accessToken)
    if (!decode.permission) {
      localStorage.clear()
    }

    dispatch(setDecodeToken(decode))
  }, [accessToken, getPermissionReducer])
}

export default useIntitialDecode
