// Lib
import React from 'react'
import { useTranslation } from 'react-i18next'

// Include in project
import styles from './index.module.scss'
import { UploadFile } from 'component'

type Props = {
  isDelayTime?: boolean
}

const DocumentCampaign: React.FC<Props> = ({ isDelayTime = false }) => {
  const { t } = useTranslation()

  return (
    <section className={styles.container}>
      <h5>{t<string>('common.documents')}</h5>
      <UploadFile isDelayTime={isDelayTime} />
    </section>
  )
}
export default DocumentCampaign
